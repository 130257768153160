<template>
  <div class="betslip-area">
    <GamesBetslipD bgImage="../assets/empty-betslip.png" :showMinimizeButton="true"></GamesBetslipD>
  </div>
</template>

<script>
import { GamesBetslipD } from '@nsftx/games-sdk-js';

export default {
  name: 'BetslipArea',
  components: {
    GamesBetslipD,
  },
};
</script>

<style lang="scss">
.betslip-area {
  .betslip-area {
    .betslipDWrapper {
      div {
        .betslipFooter {
          .betslipFooterLower {
            .possibleWinWrapper {
              .payOut {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
